<template>
  <div
    v-click-outside="closeMenu"
    class="action-submenu"
  >
    <slot name="button" />
    <div
      v-if="show"
      class="action-submenu-list"
      :class="position"
    >
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionSubmenu',
  components: {
    Action: () => import('@/components/general/Action')
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'right'
    }
  },
  methods: {
    closeMenu () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style src="@/assets/styles/themes/default/actionsubmenu.css"></style>
